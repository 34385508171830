import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const perfectdayCustomerGroup = 'perfectday';
export const customerGroup = perfectdayCustomerGroup;

const supportEmail = {
	perfectday: 'perfectday@smoothr.de'
};
const firebaseConfig = {
	perfectday: {
		apiKey: 'AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:8440e98f80c92925e0fe06',
		measurementId: 'G-DN7FX2VYS1'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyASyhGnKtZa_Id2l-Gp9_vWIDDIIvlK2OE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de']
};

import 'zone.js/dist/zone-error';
